<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <!-- <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectApprovedVendorCheckbox"
                  :indeterminate="isSelectApprovedVendorCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedVendors.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'Vendor')"
                  v-b-modal.modal-update-status-pending-vendor-bulk
                >
                  Update Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('delete', 'Vendor')"
                  @click="deleteBulkVendors"
                >
                  Delete Vendor(s)
                </b-dropdown-item>
              </b-dropdown>
            </div> -->
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectApprovedVendorCheckbox"
                :indeterminate="isSelectApprovedVendorCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedVendors.length"
            >
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Vendor')"
                v-b-modal.modal-update-status-pending-vendor-bulk
              >
                Update Status
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('delete', 'Vendor')"
                @click="deleteBulkVendors"
              >
                Delete vendor(s)
              </b-dropdown-item>
            </b-dropdown> -->
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-pending-vendor"
                ref="filter_dropdown_pending_service_form"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <vendor-filters
                  :status-filter.sync="statusFilter"
                  :created-at.sync="createdAtFilter"
                  :status-options="statusOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="createdAtFilter || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refApprovedVendorsTable"
        class="position-relative event_list"
        :class="{ low_items: vendorData.length < 4 }"
        :items="fetchVendors"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Vendor')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id"
              :to="{ name: 'purchasing-vendors-show', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
            </b-button>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.createdAt) }}</span>
          </div>
        </template>

        <template #cell(lastOrder)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'PurchaseRequest')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.lastOrder ? data.item.lastOrder._id : 0 } }"
              >
                {{ data.item.lastOrder ? data.item.lastOrder.caseID : '' }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.lastOrder ? data.item.lastOrder.caseID : '' }}</span>
            </div>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveVendorStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'Vendor') || canViewThisAction('update', 'Vendor') || canViewThisAction('delete', 'Vendor')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Vendor')"
              :to="{ name: 'purchasing-vendors-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Vendor')"
              :to="{ name: 'purchasing-vendors-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'Vendor')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalVendors > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalVendors"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-pending-vendor-bulk"
      ref="update-status-modal-pending-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="ApprovedVendorsBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton, BFormTag, BForm, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import vSelect from 'vue-select'

import store from '@/store'

import useApprovedVendorList from './useApprovedVendorList'
import vendorStoreModule from '../vendorStoreModule'
import VendorFilters from '../VendorFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    VendorFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bulkStatus: 'approved',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Approved', code: 'approved' },
        { title: 'Pending', code: 'pending' },
        { title: 'Non Approved', code: 'non approved' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_pending_service_form.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_pending_service_form.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    clearAllFilters() {
      this.createdAtFilter = ''
      this.statusFilter = []
    },
    updateBulkStatus() {
      this.$refs.ApprovedVendorsBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedVendors.length; i++) {
            formData.append('serviceForms[]', this.selectedVendors[i])
          }

          this.$http.patch('purchase/vendors/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountVendor')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected service forms has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkVendors() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected vendor(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedVendors.length; i++) {
              formData.append('serviceForms[]', this.selectedVendors[i])
            }

            this.$http.patch('purchase/vendors/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.refetchData()
                this.$emit('emitCountVendor')
                this.selectedVendors = []
                // this.$nextTick(() => {
                //   this.$refs['update-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Vendor(s) Deleted!',
                  html: 'Selected vendor(s) has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteVendor()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const VENDOR_APP_STORE_MODULE_NAME = 'app-approved-vendors'

    // Register module
    if (!store.hasModule(VENDOR_APP_STORE_MODULE_NAME)) store.registerModule(VENDOR_APP_STORE_MODULE_NAME, vendorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VENDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(VENDOR_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Approved', value: 'approved' },
      { label: 'Pending', value: 'pending' },
      { label: 'Non Approved', value: 'non approved' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchVendors,
      tableColumns,
      perPage,
      currentPage,
      totalVendors,
      vendorData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refApprovedVendorsTable,
      refetchData,

      toDeletedID,
      deleteVendor,

      // UI
      resolveVendorStatusVariant,

      // Extra Filters
      statusFilter,
      createdAtFilter,
    } = useApprovedVendorList()

    const selectedVendors = ref([])
    const toggleselectedVendors = VendorId => {
      const VendorIndex = selectedVendors.value.indexOf(VendorId)

      if (VendorIndex === -1) selectedVendors.value.push(VendorId)
      else selectedVendors.value.splice(VendorIndex, 1)
    }
    const selectApprovedVendorCheckbox = computed(() => vendorData.value.length && (vendorData.value.length === selectedVendors.value.length))
    const isSelectApprovedVendorCheckboxIndeterminate = computed(() => Boolean(selectedVendors.value.length) && vendorData.value.length !== selectedVendors.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedVendors.value = val ? vendorData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchVendors,
      tableColumns,
      perPage,
      currentPage,
      totalVendors,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refApprovedVendorsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteVendor,
      vendorData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveVendorStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      createdAtFilter,

      selectApprovedVendorCheckbox,
      isSelectApprovedVendorCheckboxIndeterminate,
      selectedVendors,
      toggleselectedVendors,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
